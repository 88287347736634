<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <ValidationObserver
      v-slot="{ valid }"
      slim
    >
      <v-card>
        <v-card-text
          v-show="!!message"
          class="pa-4"
        >
          <div v-html="message" />
        </v-card-text>
        <v-col v-if="options.validate">
          <VTextFieldWithValidation
            dense
            hide-details
            name="Confirmation text"
            :label="options.validateLabel"
            :rules="{ required: true, is: options.validate }"
          />
        </v-col>
        <v-card-actions class="pt-0">
          <v-spacer />
          <v-btn
            color="primary darken-1"
            :disabled="options.validate && !valid"
            @click.native="agree"
          >
            <span v-t="options.confirmBtnLabel" />
          </v-btn>
          <v-btn
            color="grey"
            text
            @click.native="cancel"
          >
            <span v-t="options.cancelBtnLabel" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
const defaultOptions = Object.freeze({
  validate: null,
  color: 'primary',
  confirmBtnLabel: 'general.yes',
  cancelBtnLabel: 'general.cancel',
  width: 290,
  zIndex: 200
});
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: { ...defaultOptions }
  }),
  methods: {
    open (title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign({ ...defaultOptions }, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree () {
      this.resolve(true);
      this.dialog = false;
    },
    cancel () {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
