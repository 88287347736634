// Line Chart
import { Line } from 'vue-chartjs';
import { ChartConfig } from '@/constants/chart-config';
import { toLocalDate } from '../../../../plugins/timewizard';

export default {
  extends: Line,
  props: { options: { type: Object } },
  mounted () {
    this.renderChart({
      labels: this.$props.options.labels,
      datasets: [
        {
          label: this.$props.options.name,
          fill: true,
          backgroundColor: ChartConfig.color.success,
          data: this.$props.options.data
        }
      ]
    }, {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          ticks: {
            callback: function (value) {
              return toLocalDate(value);
            }
          }
        }]
      }
    });
  },
  watch: {
    $props: {
      handler () {
        if (this.$data._chart) { this.$data._chart.update(); }
      },
      deep: true,
      immediate: true
    }
  }
};
