import Chart from 'chart.js';

Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  color: '#f7fef6',
  font: {
    weight: 'bold',
    size: 15
  },
  textStrokeColor: '#000',
  textStrokeWidth: 2,
  formatter: function (value, context) {
    const result = '';
    if (value === -1) {
      return '₺ 0.0';
    } else if (value > 0) {
      return `₺ ${value.toFixed(1)}`;
    }
    return result;
  }
});
