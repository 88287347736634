export default {
  data () {
    return {
      _loading: true
    };
  },
  methods: {
    $startLoading () {
      this.$data._loading = true;
    },
    $endLoading () {
      this.$data._loading = false;
    }
  }
};
