import { NuksClient } from '@nuks/nuks-js';
import CONFIG from '../utils/CONFIG';
import { CommissionApi } from '@/service/comission';
import { ServiceApi } from '@/service/service';
import { WebhookApi } from '@/service/webhook';
import { BrandSettingsApi } from '@/service/brand-settings';
import store from '@/store';

export const API = new NuksClient({ url: CONFIG.API_URL });
export const API_SANDBOX = new NuksClient({ url: CONFIG.SANDBOX_URL });

function addExtrasToApi (api) {
  api.commission = CommissionApi(api.getHttp());
  api.brandSettings = BrandSettingsApi(api.getHttp());
  Object.assign(api.service, ServiceApi(api.getHttp()));
  Object.assign(api.webhook, WebhookApi(api.getHttp()));
}

addExtrasToApi(API);
addExtrasToApi(API_SANDBOX);

export function getApi () {
  return store.getters['preferences/getLivemode']() ? API : API_SANDBOX;
}

export default API;
