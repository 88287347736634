<template>
  <v-app>
    <v-row
      align="center"
      class="justify-center"
    >
      <v-col
        md="3"
        sm="6"
        cols="12"
      >
        <v-card
          xs12
          style="z-index: 15;"
          class="elevation-1 pa-4"
          md4
          lg4
        >
          <v-select
            v-model="selectedTenant"
            :label="'Services'"
            hide-details
            outlined
            :items="$store.state.tenants.tenants"
            return-object
            item-text="name"
            item-value="name"
          >
            <template #item="{item}">
              <v-list-item-content>
                {{ item.name }}
              </v-list-item-content>
            </template>
            <template #selection="{item}">
              {{ item.name }}
            </template>
          </v-select>
          <v-col
            class="text-center pt-4"
          >
            <v-btn
              class="mx-auto"
              :disabled="!selectedTenant"
              color="primary"
              @click="selectTenant"
            >
              <span>Proceed</span>
              <v-icon
                right
                dark
              >
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <CloudyBackground />
  </v-app>
</template>

<script>
import CloudyBackground from '@/modules/@core/components/CloudyBackground';
import API from '@/service/index';

export default {
  name: 'SelectTenant',
  components: {
    CloudyBackground
  },
  data () {
    return {
    };
  },
  computed: {
    selectedTenant: {
      get () {
        return this.$store.state.preferences.selectedTenant;
      },
      set (v) {
        this.$store.commit('preferences/setSelectedTenant', v);
      }
    },
    tenants () {
      return this.$store.state.tenants.tenants;
    }
  },
  async mounted () {
    await this.$store.dispatch('tenants/getUserTenants');
    if (!this.tenants || !this.tenants.length) {
      this.$router.push({ path: '/createTenant' });
    }
  },
  methods: {
    async getUserTenants () {
      return API.service.getAll(null);
    },
    async selectTenant () {
      this.$router.push({ name: 'home' });
    }
  }
};
</script>

<style scoped >

  .container {
    min-height: 100%;
  }
  .container {
    color: #FFF;
    background-color: #ffdeb3;
    /*background-image: radial-gradient(circle, #F9A72B 0%, #FA9026 70%, #FB6C1F 100%);*/
  }

</style>
