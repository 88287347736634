export function toLocalDate (dateStr) {
  return dateStr ? new Date(dateStr).toLocaleDateString() : '';
}

export function toLocalTimestamp (dateStr) {
  return dateStr ? new Date(dateStr).toLocaleString() : '';
}

export default function timewizardPlugin (vue) {
  vue.prototype.$lT = toLocalTimestamp;
  vue.prototype.$lD = toLocalDate;
}
