import { lazyLoadView } from '@/utils/lazy_route_loader';

export default [
  {
    path: 'webhooks',
    // @ts-ignore
    component: () => lazyLoadView(import(/* webpackChunkName: "webhooks" */'@/modules/webhooks/pages/Webhooks')),
    meta: { title: 'webhooks.@page.title' },
    name: 'webhooks'
  },
  {
    path: 'webhooks/:id',
    name: 'webhookDetails',
    pathToRegexpOptions: { strict: true },
    component: () => lazyLoadView(import(/* webpackChunkName: "webhookDetails" */'@/modules/webhooks/pages/WebhookDetails'))
  }
];
