// Pie chart
import { Pie } from 'vue-chartjs';
import { ChartConfig } from '@/constants/chart-config';

export default {
  extends: Pie,
  props: { options: { type: Object } },
  data () {
    return {
      styleOptions: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center'
        },
        responsive: true,
        maintainAspectRatio: true
      }
    };
  },
  mounted () {
    this.renderPie();
  },
  watch: {
    $props: {
      handler () {
        if (this.$data._chart) {
          this.renderPie();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    renderPie () {
      this.renderChart({
        labels: this.$props.options.labels,
        datasets: [{
          data: this.$props.options.data,
          backgroundColor: [
            ChartConfig.color.primary,
            ChartConfig.color.danger,
            ChartConfig.color.warning
          ],
          borderWidth: [0, 0, 0],
          hoverBackgroundColor: [
            ChartConfig.color.primary,
            ChartConfig.color.danger,
            ChartConfig.color.warning
          ]
        }]
      }, this.styleOptions);
    }
  }
};
