import { lazyLoadView } from '../../../utils/lazy_route_loader';

export default [
  {
    path: 'brand-settings',
    component: () => lazyLoadView(import(/* webpackChunkName: "brandSettings" */'@/modules/brand-settings/pages/BrandSettings')),
    meta: { title: 'brand-settings.@page.title' },
    name: 'brand-settings'
  }
];
