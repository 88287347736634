/* eslint-disable handle-callback-err */
import Vue from 'vue';
import './plugins/vee_validate';
import './plugins/oidc';
import 'chartjs-plugin-datalabels';
import './plugins/charts';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import i18n from './i18n';
import './scss/_style.scss';
import { VApp } from 'vuetify/lib/components/VApp';
import CONFIG from './utils/CONFIG';
import timewizardPlugin from './plugins/timewizard';

Vue.config.productionTip = false;
Vue.component('VuePerfectScrollbar', VuePerfectScrollbar);
Vue.use(timewizardPlugin);

console.log(`Nüks Dashboard version ${CONFIG.APP_VERSION}`);

const main = new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(VApp, { attrs: { } }, [h('router-view')])
}).$mount('#app');

export default main;
