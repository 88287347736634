import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, min, max, max_value as maxValue, min_value as minValue, regex, is, numeric, alpha_num, email, integer, size } from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
  ...required,
  message: field => `${field} is required`
});

extend('size', {
  ...size,
  message: (field, { size }) => `File cannot be larger than ${size} kB`
});
extend('min', { ...min });
extend('is', { ...is });
extend('regex', { ...regex });
extend('max', { ...max });
extend('numeric', numeric);
extend('alpha_num', alpha_num);
extend('email', email);
extend('integer', integer);
extend('maxValue', {
  ...maxValue,
  message: (field, { max }) => `${field} cannot be bigger than ${max}`
});
extend('minValue', {
  ...minValue,
  message: (field, { min }) => `${field} cannot be lower than ${min}`
});

extend('password', {
  params: ['min', 'max', 'lowerCase', 'upperCase', 'customChars', 'number'],
  validate: (value, { min, max, number, lowerCase, upperCase, customChars, customRegexp }) => {
    lowerCase = Number(lowerCase);
    upperCase = Number(upperCase);
    number = Number(number);
    if (lowerCase && !new RegExp(`(?=(.*[a-z])${typeof lowerCase === 'number' && lowerCase > 0 ? '{' + lowerCase + ',}' : ''})`).test(value)) {
      return `Must contain at least ${lowerCase === true ? 1 : lowerCase} lowercase letter`;
    }
    if (upperCase && !new RegExp(`(?=(.*[A-Z])${typeof upperCase === 'number' && upperCase > 0 ? '{' + upperCase + ',}' : ''})`).test(value)) {
      return `Must contain at least ${upperCase === true ? 1 : upperCase} uppercase letter`;
    }
    if (number && !new RegExp(`(?=(.*[0-9])${typeof number === 'number' && number > 0 ? '{' + number + ',}' : ''})`).test(value)) {
      return `Must contain at least ${number === true ? 1 : number} number`;
    }
    if (min != null && value.length < min) {
      return `At least ${min} characters`;
    }
    if (max != null && value.length > max) {
      return `Maximum ${max} characters`;
    }
    if (customChars && !customChars.split('').some(char => value.indexOf(char) > -1)) {
      return `Must contain one of ${customChars}`;
    }
    try {
      if (customRegexp && customRegexp.value) {
        if (!new RegExp(customRegexp).test(customRegexp.value)) {
          return customRegexp.message || ' ';
        }
      }
    } catch (e) {
      return 'Regexp failed with' + e.message;
    }
    return true;
  }
});

const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
extend('url', {
  validate: (value) => {
    return urlRegex.test(value);
  },
  message: (field) => {
    return `${field} is not a valid URL`;
  }
});

extend('decimal', {
  params: ['decimals', 'separator'],
  validate: (value, { decimals = '2', separator = '.' }) => {
    const isNaN = Number.isNaN(parseFloat(value));
    if (value === null || value === undefined || value === '' || isNaN) {
      return 'Must be a number';
    }
    if (Number(decimals) === 0) {
      return /^-?\d*$/.test(value);
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);
    return regex.test(value);
  },
  message: 'Must be a decimal value with maximum 2 decimal places'
});

extend('phone', {
  message (field, args) {
    return 'Not a valid phone number';
  },
  validate (value, args) {
    // Custom regex for a phone number
    const MOBILEREG = /^\+[0-9]{11,13}$/;

    // Check for either of these to return true
    return MOBILEREG.test(value);
  }
});

extend('json', {
  validate (value, args) {
    try {
      JSON.parse(value.replace(/(?:\\[rn])+/g, ''));
      return true;
    } catch (e) {
      return e.message;
    }
  }
});

extend('ip', {
  message () {
    return 'Not a valid IP address';
  },
  validate (value) {
    const ipRegex = /^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$/;
    return ipRegex.test(value);
  }
});
