import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {},
  numberFormats: {
    USD: {
      currency: {
        style: 'currency', currency: 'USD'
      }
    },
    TRY: {
      currency: {
        style: 'currency', currency: 'TRY'
      }
    },
    EUR: {
      currency: {
        style: 'currency', currency: 'EUR'
      }
    }
  }
});

const langFiles = require.context('@/modules', true, /.*\/i18n\/(.*)\.json$/); // get language json files of every module
langFiles.keys().forEach(function (fileName) {
  // get language identifier from filename
  const lang = fileName.match(/([^/]+)\.json$/)[1];

  // merge language file into vue-i18n
  i18n.mergeLocaleMessage(lang, langFiles(fileName));
});

export default i18n;
