/* eslint-disable */
import {Log, UserManager} from "oidc-client";
import once from 'lodash.once'
import API, {API_SANDBOX} from "../../service";

function attach_events() {
    this.mgr.events.addUserLoaded((user) => {
        API.setKey(user.access_token);
        API_SANDBOX.setKey(user.access_token);
        this.eventListeners['userLoaded'].forEach(e => e.call(this, user));

        this.user = user.profile;
        this.access_token = user.access_token;
        this.id_token = user.id_token;
        this.session_state = user.session_state;
        this.scopes = user.scopes;

        this.log.debug('New User Loaded：', ...arguments);
    });

    this.mgr.events.addUserUnloaded((e) => {
        console.debug('userUnload')
    });

    this.mgr.events.addAccessTokenExpiring(() => {
        this.eventListeners['accessTokenExpiring'].forEach(e => e.call(this, ...arguments));
        // this.log.debug('AccessToken Expiring：', ...arguments);
    });

    this.mgr.events.addAccessTokenExpired(() => {
        this.eventListeners['accessTokenExpired'].forEach(e => e.call(this, ...arguments));
    });

    this.mgr.events.addSilentRenewError(() => {
        this.eventListeners['silentRenewError'].forEach(e => e.call(this, ...arguments));
        this.mgr.signoutRedirect().then((resp) => {
            this.log.debug('signed out', resp);
        }).catch((err) => {
            //this.log.error(err)
        })
    });


    this.mgr.events.addUserSignedOut(() => {
        console.debug('userSignOut')
        this.eventListeners['userSignedOut'].forEach(e => e.call(this, ...arguments));
    });
}

Log.logger = console;

export default class AuthService {

    constructor(options) {
        this.options = options;
        this.mgr = new UserManager(this.options.config);

        switch (options.logLevel) {
            case "DEBUG":
            case 4:
                Log.level = Log.DEBUG;
                break;
            case "INFO":
            case 3:
                Log.level = Log.INFO;
                break;
            case "WARN":
            case 2:
                Log.level = Log.WARN;
                break;
            case "ERROR":
            case 1:
                Log.level = Log.ERROR;
                break;
            case "NONE":
            case 0:
                Log.level = Log.NONE;
                break;
            default:
                Log.level = Log.ERROR
        }
        this.log = Log;

        this.eventListeners = {
            userLoaded: [],
            userUnloaded: [],
            error: [],
            accessTokenExpiring: [],
            accessTokenExpired: [],
            silentRenewError: [],
            userSignedOut: []
        };

        this.getUser()


        attach_events.call(this)
    }

    setLocale(locale) {
        this.options.locale = locale
    }

    $on(event, callback) {
        if (this.eventListeners[event] === undefined) {
            this.log.error(`"${event}" is not a defined event.`)
        } else {
            this.eventListeners[event].push(callback)
        }
    }

    $off(event, callback) {
        if (this.eventListeners[event] === undefined) {
            this.log.error(`"${event}" is not a defined event.`)
        } else {
            for (let i = 0; i < this.eventListeners[event].length; i++) {
                if (this.eventListeners[event][i] === callback) {
                    this.eventListeners[event].slice(i, 1)
                }
            }
        }
    }

    async checkLogin(silent) {
        try {
            const user = await this.mgr.getUser();
            if (user && !user.expired) {
                API.setKey(user.access_token);
                API_SANDBOX.setKey(user.access_token);
                this.user = user.profile;
                this.access_token = user.access_token;
                this.id_token = user.id_token;
                this.session_state = user.session_state;
                this.scopes = user.scopes;
                this.eventListeners['userLoaded'].forEach(e => e.call(this, user));
                return true
            } else {
                if (silent) {
                    return await this.signInSilent(undefined)

                } else {
                    return this.signIn()
                }
            }
        } catch (e) {
            return this.signIn()
        }
    }

    async renewToken() {
        return this.mgr.signinSilent()
    }

    async signIn(state) {
        const _state = {
            location: window.location.href,
            data: state
        };
        return this.mgr.signinRedirect({ state: _state, ui_locales: this.options.locale });
    }
    async signInSilent(state) {
        const _state = {
            location: window.location.href,
            data: state
        };
        return this.mgr.signinSilent({ state: _state, ui_locales: this.options.locale});
    }

    async signOut() {
        return this.mgr.signoutRedirect(arguments);
    }

    async isSignedIn() {
        const user = await this.mgr.getUser();
        return !!user;
    }


    async getIdToken() {
        const user = await this.mgr.getUser();
        return user ? user.id_token : null;
    }

    async getSessionState() {
        const user = await this.mgr.getUser();
        return user ? user.session_state : null;
    }

    async getAcessToken() {
        const user = await this.mgr.getUser();
        return user ? user.access_token : null;
    }

    async getScopes() {
        const user = await this.mgr.getUser();
        return user ? user.scopes : null;
    }

}

AuthService.prototype.getUser = once(async function(){
    const user = await this.mgr.getUser();
    if (user) {
        this.user = user.profile;
        this.access_token = user.access_token;
        this.id_token = user.id_token;
        this.session_state = user.session_state;
        this.scopes = user.scopes;
        this.eventListeners['userLoaded'].forEach(e => e.call(this, user));
    } else {
        //console.debug(user)
    }
    return this.user;
})
