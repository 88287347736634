import API from '@/service/index';
import Vue from 'vue';
const state = {
  tenants: []
};
export default {
  namespaced: true,
  state,
  getters: {},
  mutations: {
    setTenants (state, tenants) {
      state.tenants = tenants;
    },
    addTenant (state, tenant) {
      state.tenants.push(tenant);
    },
    // eslint-disable-next-line camelcase
    removeTenant (state, id) {
      // eslint-disable-next-line camelcase
      state.tenants.splice(state.tenants.findIndex(t => t.id === id), 1);
    }
  },
  actions: {
    async getUserTenants (context) {
      // Dont load until user is fully logged in
      if (!await Vue.$oidc.checkLogin()) { return null; }

      try {
        let results = null;

        results = (await API.service.getAll({ items_per_page: 100 })).results;

        context.commit('setTenants', results);
        context.commit('preferences/refreshSelectedTenant', null, { root: true });
        return results;
      } catch (e) {
        if (e.status === 401) {
          context.commit('preferences/setSelectedTenant', {});
        } else {
          console.debug(e);
        }
        return [];
      }
    }
  }
};
