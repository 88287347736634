export const WebhookApi = (http) => ({
  baseUrl: '/webhook',

  async getAvailableEvents () {
    return this.http.get(`${this.baseUrl}/available-events`);
  },

  async createCredentials (id) {
    return this.http.post(`${this.baseUrl}/${id}/credentials`);
  },

  async getCredentials (id) {
    return this.http.get(`${this.baseUrl}/${id}/credentials`);
  }
});
