import { lazyLoadView } from '../../../utils/lazy_route_loader';
import TenantSettings from '@/modules/settings/pages/TenantSettings';

export default [
  {
    path: 'settings',
    name: 'settings',
    redirect: { name: 'generalTenantSettings' },
    component: TenantSettings,
    children: [
      {
        path: 'general',
        name: 'generalTenantSettings',
        meta: { title: 'tenantSettings.@page.title' },
        component: () => lazyLoadView(import(/* webpackChunkName: "GeneralSettings" */'@/modules/settings/components/General'))
      },
      {
        path: 'api',
        name: 'apiSettings',
        meta: { title: 'tenantSettings.@page.title' },
        component: () => lazyLoadView(import(/* webpackChunkName: "ApiSettings" */'@/modules/settings/components/Api'))
      },
      {
        path: 'commission',
        name: 'commissionSettings',
        meta: { title: 'tenantSettings.@page.title' },
        component: () => lazyLoadView(import(/* webpackChunkName: "CommissionSettings" */'@/modules/settings/components/Commission'))
      }
    ]
  }
];
