import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import preferences from '@/modules/@core/store/preferences';
import tenants from '@/modules/@core/store/tenants';
const modules = {
  preferences,
  tenants
};

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['preferences']
});

const store = new Vuex.Store({
  modules,
  plugins: [vuexLocal.plugin],
  state: {},
  mutations: {},
  actions: {}
});

// populate vuex modules
const requireModule = require.context(
  '@/modules',
  true,
  /.*\/store\/index\.js/,
  'lazy'
); // extract js files inside modules folder

const excluded = ['tenants', 'preferences'];
requireModule.keys().forEach(async (fileName) => {
  const moduleName = fileName.split('/')[1]; // get module name
  const { default: defaultModule } = await requireModule(fileName); // add module itself to the modules

  if (Array.isArray(defaultModule)) {
    defaultModule.forEach(m => {
      if (!excluded.includes(m.name)) {
        if (m.nested) {
          store.registerModule(moduleName + '/' + m.name, m.store);
        } else {
          store.registerModule(m.name, m.store);
        }
      }
    });
  } else {
    if (!excluded.includes(moduleName)) {
      store.registerModule(moduleName, defaultModule);
    }
  }
});

export default store;
