import { lazyLoadView } from '../../../utils/lazy_route_loader';

export default [
  {
    path: 'products',
    component: () => lazyLoadView(import(/* webpackChunkName: "products" */'@/modules/products/pages/Products')),
    meta: { title: 'products.@page.title' },
    name: 'products'
  },
  {
    path: 'products/:id',
    name: 'productDetails',
    pathToRegexpOptions: { strict: true },
    component: () => lazyLoadView(import(/* webpackChunkName: "webhookDetails" */'@/modules/products/pages/ProductDetails'))
  }
];
