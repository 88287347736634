import { lazyLoadView } from '../../../utils/lazy_route_loader';

export default [
  {
    path: 'customers',
    component: () => lazyLoadView(import(/* webpackChunkName: "payments" */'@/modules/customers/pages/Customers')),
    meta: { title: 'customers.@page.title' },
    name: 'customers'
  },
  {
    path: 'customers/:id/',
    pathToRegexpOptions: { strict: true },
    component: () => lazyLoadView(import(/* webpackChunkName: "paymentDetails" */'@/modules/customers/pages/CustomerDetails')),
    children: [
      {
        path: 'details',
        name: 'customerDetails'
      },
      {
        path: 'invoices',
        name: 'customerInvoices'
      },
      {
        path: 'paymentSources',
        name: 'customerPaymentSources'
      }
    ]
  }
];
