import AuthService from './auth_service';

export { AuthService };

function install (Vue, options = {}) {
  const authService = new AuthService(options);
  Vue.prototype.$oidc = authService;
  Vue.prototype.constructor.$oidc = authService;
}
export default install;
