export const BrandSettingsApi = (http) => ({
  path: '/brand-settings',
  http: http,

  async get () {
    return await this.http.get(this.path);
  },

  async update ({ logo, name, style }) {
    return await this.http.patch(this.path, { data: { logo, name, style } });
  }
});
