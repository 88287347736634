export const ServiceApi = (http) => ({
  baseUrl: '/service',

  async createCredentials (id) {
    return this.http.post(`${this.baseUrl}/${id}/credentials`, { data: {} });
  },

  async getCredentials (id) {
    return this.http.get(`${this.baseUrl}/${id}/credentials`);
  },

  async setCommission (id, commission_id) {
    return this.http.patch(`${this.baseUrl}/${id}/commission`, { data: { commission_id } });
  },

  async updateSandboxId (id, sandbox_id) {
    return this.http.patch(`${this.baseUrl}/${id}/sandbox`, { data: { id: sandbox_id } });
  }
});
