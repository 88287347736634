<template>
  <div
    class="sidebar"
    :class="sidebarSelectedFilter.class"
  >
    <vue-perfect-scrollbar
      class="scroll-area"
      :settings="settings"
    >
      <div class="transparent navigation">
        <v-list>
          <app-logo style="margin:  10px auto" />
          <!--          <user-block />-->

          <template v-for="item in menuItems">
            <template v-if="!item.hidden">
              <template v-if="item.kind === 'divider'">
                <!-- eslint-disable-next-line vue/valid-v-for -->
                <v-divider />
              </template>
              <template v-else-if="item.items != null">
                <v-list-group
                  :key="item.title"
                  v-model="item.active"
                  :ripple="false"
                  prepend-icon=""
                  append-icon=""
                  no-action
                >
                  <template #appendIcon>
                    <v-list-item-icon class="mr-6">
                      <v-icon>
                        mdi-chevron-down
                      </v-icon>
                    </v-list-item-icon>
                  </template>
                  <v-list-item
                    slot="activator"
                    :ripple="false"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-if="item.items!= null">
                        <v-avatar
                          v-if="item.image"
                          class="mr-3"
                          size="24"
                        >
                          <v-img
                            :src="item.image"
                          />
                        </v-avatar>
                        <v-icon
                          v-else
                          class="mr-3"
                        >
                          {{ item.icon }}
                        </v-icon>
                        <span>{{ item.rawTitle ? item.title : textTruncate($t(item.title)) }}</span>
                        <template v-if="item.label == 'New'">
                          <span class="sidebar-label">{{ item.label }}</span>
                        </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="subItem in item.items"
                    :key="subItem.title"
                    :ripple="false"
                    :disabled="subItem.disabled"
                    :exact="subItem.exact"
                    :to="subItem.path"
                  >
                    <template v-if="subItem !== null && !subItem.hidden">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon
                            v-if="subItem.icon != null"
                            class="mr-3"
                          >
                            {{ subItem.icon }}
                          </v-icon>
                          {{ textTruncate($t(subItem.title)) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-group>
              </template>
              <template v-else>
                <v-list-group
                  :key="item.title"
                  v-model="item.active"
                  group
                  :ripple="false"
                  class="not-submenu"
                  append-icon=""
                  no-action
                >
                  <v-list-item
                    slot="activator"
                    :ripple="false"
                    :exact="item.exact"
                    :to="item.path"
                    :disabled="item.disabled"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-img
                          v-if="item.image"
                          :src="item.image"
                        />
                        <v-icon
                          v-else
                          class="mr-3"
                        >
                          {{ item.icon }}
                        </v-icon>
                        <span>{{ item.rawTitle ? item.title : textTruncate($t(item.title)) }}</span>
                        <template v-if="item.label == 'New'">
                          <span class="sidebar-label">New</span>
                        </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
            </template>
          </template>
        </v-list>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { textTruncate } from '@/utils/text_truncate';
import { getCurrentAppLayout } from '@/utils/get_current_layout';
import AppLogo from '@/modules/@core/components/AppLogo';
import Vue from 'vue';

export default {
  name: 'AppSidebar',
  components: {
    // ProfileMenu
    AppLogo
    // NavMenuItem
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 160
      }
    };
  },
  computed: {
    sidebarSelectedFilter () {
      return this.$store.state.preferences.sidebar.selectedFilter;
    },
    menuItems () {
      return [
        {
          rawTitle: true,
          title: this.$store.state.preferences.selectedTenant.name,
          group: '/tenant',
          items: [
            { title: 'header.selectTenant', path: { name: 'selectTenant' }, icon: 'mdi-sync' },
            { title: 'header.createTenant', path: { name: 'createTenant' }, icon: 'mdi-plus' }
          ]
        },
        { kind: 'divider' },
        { title: 'dashboard.label', path: { name: 'home' }, icon: 'mdi-view-dashboard', exact: true },
        { title: 'customers.@page.title', path: { name: 'customers' }, icon: 'mdi-account-group' },
        { title: 'payments.@page.title', path: { name: 'payments' }, icon: 'mdi-cash-multiple' },
        { title: 'products.@page.title', path: { name: 'products' }, icon: 'mdi-cart-outline' },
        { title: 'plans.@page.title', path: { name: 'plans' }, icon: 'mdi-clipboard-list-outline' },
        { title: 'subscriptions.@page.title', path: { name: 'subscriptions' }, icon: 'mdi-autorenew' },
        { title: 'discounts.@page.title', path: { name: 'discounts' }, icon: 'mdi-ticket-percent' },
        { title: 'webhooks.@page.title', path: { name: 'webhooks' }, icon: 'mdi-hook' },
        { title: 'brand-settings.@page.title', path: { name: 'brand-settings' }, icon: 'mdi-palette' },
        { title: 'header.settings', path: { name: 'settings' }, icon: 'mdi-cog' }
      ];
    }
  },
  methods: {
    textTruncate (text) {
      return textTruncate(text, 24);
    },
    getCurrentAppLayoutHandler () {
      return getCurrentAppLayout(this.$router);
    }
  }
};
</script>

<style lang="scss">
</style>
