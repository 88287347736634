<template>
  <ModuleLayout
    no-gutters
    header="tenantSettings.@page.title"
    sub-header="tenantSettings.@page.description"
  >
    <v-tabs
      v-model="selectedTab"
      centered
      show-arrows
      height="48"
      style="position: sticky; top: 0; z-index: 3"
      icons-and-text
    >
      <v-tabs-slider />

      <v-tab
        v-for="(setting, key) in settings"
        :key="key"
        :to="setting.path"
      >
        <span v-t="'tenantSettings.@page.'+ setting.name" />
      </v-tab>
      <v-tabs-items
        v-model="selectedTab"
        mandatory
        continuous
      >
        <v-container>
          <router-view />
        </v-container>
      </v-tabs-items>
    </v-tabs>
  </ModuleLayout>
</template>

<script >
import ModuleLayout from '@/modules/@core/components/ModuleLayout';

export default {
  name: 'TenantSettings',
  components: {
    ModuleLayout
  },
  data () {
    return {
      selectedTab: null,
      settings: [
        {
          path: { name: 'generalTenantSettings' },
          name: 'general'
        },

        {
          path: { name: 'apiSettings' },
          name: 'api'
        },

        {
          path: { name: 'commissionSettings' },
          name: 'commission'
        }
      ]

    };
  },
  async created () {
  },
  methods: {

  }
};
</script >

<style scoped >

</style >
