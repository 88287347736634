<template>
  <v-app>
    <div class="row align-center justify-center ">
      <div>
        <div class="layout">
          <div
            class="a"
            style="--n: 5;"
          >
            <div
              class="dot"
              style="--i: 0;"
            />
            <div
              class="dot"
              style="--i: 1;"
            />
            <div
              class="dot"
              style="--i: 2;"
            />
            <div
              class="dot"
              style="--i: 3;"
            />
            <div
              class="dot"
              style="--i: 4;"
            />
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'OidcCallback',
  created () {}
};
</script>
<style lang="scss" scoped>
$d: 2em;
$t: 1.5s;
.dot {
  background: tomato;

  &,
  &:after {
    display: inline-block;
    width: $d;
    height: $d;
    border-radius: 50%;
    animation: a $t calc(((var(--i) + var(--o, 0)) / var(--n) - 1) *#{$t})
      infinite;
  }

  &:after {
    --o: 1;
    background: currentcolor;
    content: '';
  }
}

@keyframes a {
  0%,
  50% {
    transform: scale(0);
  }
}
</style>
