import { lazyLoadView } from '@/utils/lazy_route_loader';

export default [
  {
    path: 'discounts',
    component: () => lazyLoadView(import(/* webpackChunkName: "discounts" */'@/modules/discounts/pages/Discounts')),
    meta: { title: 'discounts.@page.title' },
    name: 'discounts'
  },
  {
    path: 'discounts/:id',
    name: 'discountDetails',
    pathToRegexpOptions: { strict: true },
    component: () => lazyLoadView(import(/* webpackChunkName: "discountDetails" */'@/modules/discounts/pages/DiscountDetails'))
  }
];
