import Vue from 'vue';
import Router from 'vue-router';
import i18n from './i18n';
import AppLayout from './modules/@core/layout/AppLayout';
import OidcCallback from '@/modules/@core/pages/OidcCallback';
import SelectTenant from '@/modules/@core/pages/SelectTenant';
import store from '@/store';
import Dashboard from '@/modules/@core/pages/Dashboard';
import { lazyLoadView } from '@/utils/lazy_route_loader';
import API, { API_SANDBOX } from './service';

Router.prototype.open = function (routeObject) {
  const { href } = this.resolve(routeObject);
  window.open(href, '_blank');
};

Vue.use(Router);

const moduleRoots = [];
const routeFiles = require.context('@/modules', true, /.*\/route\/index\.js$/); // get routes of every module
routeFiles.keys().forEach(function (fileName) {
  // append routes
  moduleRoots.push(...routeFiles(fileName).default);
});

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      beforeEnter: async (to, from, next) => {
        let existedSelectedTenant = false;
        let tenants = store.state.tenants.tenants;

        const selectedTenant = store.state.preferences.selectedTenant;

        // If tenants haven't retrieved yet retrieve them. without this selected tenant may be null
        if (tenants == null || !tenants.length) {
          tenants = await store.dispatch('tenants/getUserTenants');
        }

        // If a tenant is selected before, check if it exist in case of to be deleted
        if (selectedTenant && selectedTenant.id) {
          existedSelectedTenant = tenants.find(
            v => v.id === selectedTenant.id
          );
          if (existedSelectedTenant) {
            store.commit(
              'preferences/setSelectedTenant',
              existedSelectedTenant
            );
          }
        }

        // If no tenant exists redirect to creation page
        if (!tenants || !tenants.length) {
          store.commit('preferences/setSelectedTenant', {});
          next({ name: 'createTenant' });
        } else if (existedSelectedTenant) {
          API.setServiceId(existedSelectedTenant.id);
          API_SANDBOX.setServiceId(existedSelectedTenant.sandbox_id);
          next();
        } else if (tenants.length === 1) {
          // If there is only one tenant make it selected
          store.commit('preferences/setSelectedTenant', tenants[0]);
          next();
        } else if (tenants.length > 1) {
          store.commit('preferences/setSelectedTenant', {});
          next({ name: 'selectTenant' });
        }
      },
      component: AppLayout,
      children: [
        {
          path: '',
          name: 'home',
          component: Dashboard
        },
        ...moduleRoots
      ]
    },
    {
      path: '/createTenant',
      name: 'createTenant',
      component: () => lazyLoadView(import(/* webpackChunkName: 'createTenant' */'@/modules/@core/pages/CreateTenant'))
    },
    {
      path: '/selectTenant',
      name: 'selectTenant',
      component: SelectTenant,
      async beforeEnter (to, from, next) {
        let tenants = store.state.tenants.tenants;
        if (tenants == null || !tenants.length) {
          tenants = await store.dispatch('tenants/getUserTenants');
        }
        if (!tenants || !tenants.length) {
          store.commit('preferences/setSelectedTenant', {});
          next({ path: '/createTenant' });
        } else {
          next();
        }
      }
    },
    {
      path: '/callback', // Needs to match redirect_uri in you oidcSettings
      name: 'callback',
      component: OidcCallback,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/unauthorized',
      name: '401',
      meta: {
        isPublic: true
      },
      component: () =>
        lazyLoadView(import(
          /* webpackChunkName: "unauthorized" */ '@/modules/@core/pages/Unauthorized'
        ))
    },
    {
      path: '/error',
      name: 'error',
      meta: {
        isPublic: true
      },
      component: () =>
        import(/* webpackChunkName: "error" */ '@/modules/@core/pages/Error')
    },
    {
      path: '*',
      name: '404',
      meta: {
        isPublic: true
      },
      component: () =>
        import(
          /* webpackChunkName: "notFound" */ '@/modules/@core/pages/NotFound'
        )
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = i18n.t(to.meta.title);
  } else {
    document.title = 'Nüks';
  }

  if (to.meta.isPublic) {
    next();
  } else {
    let isLoggedIn = false;
    try {
      isLoggedIn = await Vue.$oidc.checkLogin(true);
      if (!isLoggedIn) {
        await Vue.$oidc.signIn();
      } else if (to.meta.auth && Vue.$oidc.scopes) {
        if (Vue.$oidc.scopes.indexOf(to.meta.auth) !== -1) {
          await next();
        } else {
          await next({ name: '401' });
        }
      } else {
        await next();
      }
    } catch (e) {
      next({ name: 'error', params: { error: e }, replace: false });
    }
  }
});

export default router;
