<template>
  <div class="site-logo">
    <router-link
      to="/"
      style="margin: 10px auto"
    >
      <img
        v-if="sidebarSelectedFilter.class == 'sidebar-overlay-light'"
        :src="darkLogo"
        alt="site logo"
        width="180"
        height="30"
      >
      <img
        v-else
        :src="appLogo"
        alt="site logo"
        width="100"
        height="50"
      >
    </router-link>
  </div>
</template>

<script>

export default {
  data () {
    return {
      appLogo: '/images/logo.svg',
      darkLogo: '@/assets/logo.svg'
    };
  },
  computed: {
    sidebarSelectedFilter () {
      return this.$store.state.preferences.sidebar.selectedFilter;
    }
  }
};
</script>
