<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    slim
  >
    <v-select
      v-model="innerValue"
      outlined
      dense
      :error="!!errors.length"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #append>
        <v-tooltip
          v-if="!!errors.length"
          bottom
        >
          <template #activator="{on}">
            <v-icon
              color="error"
              v-on="on"
            >
              mdi-alert-circle
            </v-icon>
          </template>
          <span>{{ errors[0] }}</span>
        </v-tooltip>
      </template>
      <slot
        v-for="(_, name) in $slots"
        :slot="name"
        :name="name"
      />
      <template
        v-for="(_, slot) of $scopedSlots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </v-select>
  </ValidationProvider>
</template>
<script>
export default {
  name: 'VSelectWithValidation',
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
