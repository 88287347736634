<template>
  <div
    class="custom-spinner-container"
    :style="{ backgroundColor: background}"
  >
    <div class="custom-spinner">
      <div class="custom-spinner-inner" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSpinner',
  props: {
    background: { type: String, default: '#f5f5f58f' }
  }
};
</script>

<style scoped lang="scss">
.custom-spinner,
.custom-spinner * {
  box-sizing: border-box;
}

.custom-spinner-container {
  z-index: 5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex: auto;
  align-items: center;
  display: flex;
  justify-content: center;

  .custom-spinner {
    height: 70px;
    width: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .custom-spinner-inner {
      width: calc(70px / 7);
      height: calc(70px / 7);
      background-color: #ff1d5e;
      color: #ff1d5e;
      box-shadow: 15px 15px 0 0, -15px -15px 0 0, 15px -15px 0 0, -15px 15px 0 0,
        0 15px 0 0, 15px 0 0 0, -15px 0 0 0, 0 -15px 0 0;
      animation: custom-spinner-animation 2000ms linear infinite;
    }
  }
}

@keyframes custom-spinner-animation {
  50% {
    box-shadow: 20px 20px 0px 0px, -20px -20px 0px 0px, 20px -20px 0px 0px,
      -20px 20px 0px 0px, 0px 10px 0px 0px, 10px 0px 0px 0px, -10px 0px 0px 0px,
      0px -10px 0px 0px;
  }
  75% {
    box-shadow: 20px 20px 0px 0px, -20px -20px 0px 0px, 20px -20px 0px 0px,
      -20px 20px 0px 0px, 0px 10px 0px 0px, 10px 0px 0px 0px, -10px 0px 0px 0px,
      0px -10px 0px 0px;
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
