import { encodeQueryString } from '@/utils/encode_query_string';

export const CommissionApi = (http) => ({
  http: http,
  commissionPath: '/commission/',
  async getAll (pagination, filters) {
    return await this.http.get(this.commissionPath + encodeQueryString({ ...pagination, ...filters }));
  },

  async get (id) {
    return await this.http.get(this.commissionPath + id);
  },

  async create ({ name, group, top_limit, flat_amount, percent }) {
    return await this.http.post(this.commissionPath, { data: { name, group, top_limit, flat_amount, percent } });
  },

  async update (id, { name, flat_amount, percent }) {
    return await this.http.patch(this.commissionPath + id, { data: { name, flat_amount, percent } });
  },

  async delete (id) {
    return await this.http.delete(this.commissionPath + id);
  }
});
