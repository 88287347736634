import { lazyLoadView } from '../../../utils/lazy_route_loader';

export default [
  {
    path: 'subscriptions',
    component: () => lazyLoadView(import(/* webpackChunkName: "payments" */'@/modules/subscriptions/pages/Subscriptions')),
    meta: { title: 'subscriptions.@page.title' },
    name: 'subscriptions'
  },
  {
    path: 'subscriptions/:id/',
    pathToRegexpOptions: { strict: true },
    component: () => lazyLoadView(import(/* webpackChunkName: "paymentDetails" */'@/modules/subscriptions/pages/SubscriptionDetails')),
    children: [
      {
        path: 'details',
        name: 'subscriptionDetails'
      },
      {
        path: 'invoices',
        name: 'subscriptionInvoices'
      },

      {
        path: 'usages',
        name: 'subscriptionUsages'
      }
    ]
  }
];
