<template>
  <v-container fluid>
    <v-row>
      <v-col
        md="4"
        sm="12"
        align-self="center"
      >
        <!-- Useful Widget -->
        <div class="dash-card-wrap">
          <h4 class="ma-3 align-center">
            Payments In Last 24H
          </h4>

          <div class="ma-8">
            <pie-chart
              :options="todayOptions"
            />
          </div>
        </div>
      </v-col>

      <v-col
        md="4"
        sm="12"
        align-self="center"
      >
        <!-- Useful Widget -->
        <div class="dash-card-wrap">
          <h4 class="ma-3 align-center">
            Payments In Last Month
          </h4>
          <div class="ma-8">
            <pie-chart
              :options="monthPositiveOptions"
            />
          </div>
        </div>
      </v-col>

      <v-col
        md="4"
        sm="12"
        align-self="center"
      >
        <!-- Useful Widget -->
        <div class="dash-card-wrap">
          <h4 class="ma-3 align-center">
            Payments In Last Month
          </h4>
          <div class="ma-8">
            <pie-chart
              :options="monthNegativeOptions"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="container">
      <line-chart
        :options="profitOptions"
      />
    </div>
  </v-container>
</template>

<script >
import PieChart from '@/modules/@core/components/charts/PieChart.js';
import LineChart from '@/modules/@core/components/charts/LineChart.js';
import API, { getApi } from '@/service';

export default {
  name: 'Dashboard',
  components: {
    PieChart,
    LineChart
  },
  data: () => ({
    todayOptions: {
      data: [
        -1, -1, -1
      ],
      labels: ['Completed', 'Canceled', 'Waiting Payment']
    },
    monthPositiveOptions: {
      data: [
        -1, -1, -1
      ],
      labels: ['Completed', 'Canceled', 'Waiting Payment']
    },
    monthNegativeOptions: {
      data: [
        -1, -1
      ],
      labels: ['Refunded', 'Canceled']

    },
    profitOptions: {

      labels: [],
      name: 'Profit',
      data: []

    }
  }),
  async mounted () {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDayOfMonth = this.dateToString(new Date(year, month, 1));
    const lastDayOfMonth = this.dateToString(new Date(year, month + 1, 0));
    const today = this.dateToString(date);
    date.setDate(date.getDate() + 1);
    const tomorrow = this.dateToString(date);

    const [todayData, monthStatusData, monthProfitData] = await Promise.all([
      getApi().payment.reportByStatus({ first_date: today, last_date: tomorrow }),
      getApi().payment.reportByStatus({ first_date: firstDayOfMonth, last_date: lastDayOfMonth }),
      getApi().payment.reportByProfit({ first_date: firstDayOfMonth, last_date: lastDayOfMonth })
    ]);

    this.todayOptions.data = [todayData.completed || -1, todayData.canceled || -1, todayData.waiting_payment || -1];

    this.monthPositiveOptions.data = [monthStatusData.completed || -1, monthStatusData.canceled || -1, monthStatusData.waiting_payment || -1];

    this.monthNegativeOptions.data = [monthStatusData.refunded || -1, monthStatusData.canceled || -1];

    for (const monthProfitDatum of monthProfitData.results) {
      this.profitOptions.labels.push(monthProfitDatum.date);
      this.profitOptions.data.push(monthProfitDatum.captured_amount || 0);
    }
  },
  methods: {
    dateToString (date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

  }
};
</script >

<style scoped >

</style >
