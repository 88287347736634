<template>
  <v-menu
    bottom
    offset-y
    left
    content-class="userblock-dropdown"
    nudge-top="-10"
    nudge-right="0"
    transition="slide-y-transition"
  >
    <template #activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <img
          v-if="userImage"
          :src="userImage"
          alt="avatar"
          height="32"
          width="32"
          class="img-responsive"
        >
        <v-icon
          v-else
          size="44"
        >
          mdi-account-circle
        </v-icon>
      </v-btn>
    </template>
    <div class="dropdown-content">
      <div class="dropdown-top white--text primary">
        <span class="white--text fs-14 fw-bold d-block">{{ userDisplayName }}</span>
        <span class="d-block fs-12 fw-normal">{{ user.username || user.email }}</span>
      </div>
      <v-list class="dropdown-list">
        <template v-for="userLink in userLinks">
          <v-list-item
            :key="userLink.id"
            :to="userLink.path ? getMenuLink(userLink.path): undefined"
            @click="userLink.action ? userLink.action() : undefined"
          >
            <v-icon>
              {{ userLink.icon }}
            </v-icon>
            <span>{{ $t(userLink.title) }}</span>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </v-menu>
</template>

<script>
/* eslint-disable camelcase */

import { getCurrentAppLayout } from '@/utils/get_current_layout';

export default {
  data () {
    return {
      user: {}
    };
  },
  computed: {
    userLinks () {
      const vm = this;
      return [
        /* {
          id: 1,
          title: 'header.@profile.profile',
          icon: 'ti-user mr-3 primary--text',
          path: { name: 'userProfile' }
        }, */
        {
          id: 4,
          title: 'header.@profile.logout',
          icon: 'mdi-power mr-3 error--text',
          action: vm.logoutUser
        }
      ];
    },
    userImage () {
      if (!this.$oidc.user) {
        return null;
      } else {
        return this.$oidc.user.picture ? this.$oidc.user.picture : undefined;
      }
    },
    userDisplayName () {
      if (!this.$oidc.user) {
        return null;
      }
      const { given_name = '', middle_name = '', family_name = '' } = this.$oidc.user;
      return ((given_name || '') +
          ' ' + (middle_name || '') + ' ' +
          (family_name || '')).trim() || this.$oidc.user.username || this.$oidc.user.email || this.$oidc.user.sub;
    },
    selectedTenant: {
      get () {
        return this.$store.state.preferences.selectedTenant;
      },
      set (v) {
        this.$store.commit('preferences/setSelectedTenant', v);
      }
    }
  },

  mounted () {
    if (this.$oidc.user) { this.user = this.$oidc.user; }
  },
  methods: {
    async logoutUser () {
      await this.$oidc.signOut();
    },
    getMenuLink (path) {
      if (typeof path === 'object') {
        return path;
      }
      return '/' + getCurrentAppLayout(this.$router) + path;
    }
  }
};
</script>
