<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="$attrs.label"
    :rules="rules"
    slim
  >
    <v-combobox
      v-model="innerValue"
      class="cb-input-down"
      dense
      outlined
      :error="!!errors.length"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #append>
        <v-tooltip
          v-if="!!errors.length"
          bottom
        >
          <template #activator="{on}">
            <v-icon
              color="error"
              v-on="on"
            >
              mdi-alert-circle
            </v-icon>
          </template>
          <span>{{ errors[0].msg ? errors[0].msg : errors[0] }}</span>
        </v-tooltip>
      </template>
      <slot
        v-for="(_, name) in $slots"
        :slot="name"
        :name="name"
      />
    </v-combobox>
  </ValidationProvider>
</template>
<script>
export default {
  name: 'VComboboxWithValidation',
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: []
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    remove (item) {
      this.innerValue.splice(this.innerValue.indexOf(item), 1);
      this.innerValue = [...this.innerValue];
    }
  }
};
</script>
<style >
.cb-input-down.v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input{
  flex: 1 1 auto;
}
</style>
