import { sidebarFilters } from '@/modules/@core/store/data';
import API, { API_SANDBOX } from '../../../service';

const state = {
  sidebar: {
    mini: false,
    selectedFilter: sidebarFilters[0]
  },
  selectedTenant: {},
  livemode: null
};

const Preferences = {
  namespaced: true,
  state,
  getters: {
    getLivemode: (state) => {
      return () => {
        const service_id = state.selectedTenant.id;
        return state.livemode && state.livemode[service_id] !== false;
      };
    },
    isActive: (state, getters) => {
      return state.selectedTenant.active || !getters.getLivemode();
    }
  },
  mutations: {
    toggle_sidebar (state) {
      state.sidebar.mini = !state.sidebar.mini;
    },
    async setSelectedTenant (state, { id } = {}) {
      if (!id) {
        state.selectedTenant = null;
        return;
      }

      state.selectedTenant = this.state.tenants.tenants.find(t => t.id === id);

      API.setServiceId(state.selectedTenant.id);
      API_SANDBOX.setServiceId(state.selectedTenant.sandbox_id);
    },
    refreshSelectedTenant (state) {
      if (state.selectedTenant) {
        state.selectedTenant = this.state.tenants.tenants.find(t => t.id === state.selectedTenant.id);
      }
    },
    setLivemode (state, { service_id, livemode }) {
      if (!state.livemode) state.livemode = {};
      state.livemode[service_id] = livemode;
    }
  },
  actions: {
    async setLivemode ({ state, commit }, { service_id, livemode }) {
      if (!livemode) {
        const service = state.selectedTenant;
        // TODO: this is a quickfix, delete before launch
        const newService = new Date(service.updated_at).getTime() > new Date(2021, 4, 5).getTime();
        if (service.sandbox_id && newService) {
          API_SANDBOX.setServiceId(service.sandbox_id);
        } else {
          const sandbox = await API_SANDBOX.service.create({ name: service.name });
          await API.service.updateSandboxId(service.id, sandbox.id);
          API_SANDBOX.setServiceId(sandbox.id);
          service.sandbox_id = sandbox.id;
          service.updated_at = new Date().toISOString();
        }
      }
      await commit('setLivemode', { service_id, livemode });
    }
  }
};

export default Preferences;
