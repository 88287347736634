import { lazyLoadView } from '@/utils/lazy_route_loader';

export default [
  {
    path: 'payments',
    component: () => lazyLoadView(import(/* webpackChunkName: "payments" */'@/modules/payments/pages/Payments')),
    meta: { title: 'payments.@page.title' },
    name: 'payments'
  },
  {
    path: 'payments/:id/',
    pathToRegexpOptions: { strict: true },
    component: () => lazyLoadView(import(/* webpackChunkName: "paymentDetails" */'@/modules/payments/pages/PaymentDetails')),
    children: [
      {
        path: 'details',
        name: 'paymentDetails'
      }
    ]
  }
];
