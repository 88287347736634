/**
* Change all chart colors
*/
export const ChartConfig = {
  color: {
    primary: '#5D92F4',
    warning: '#FFB70F',
    danger: '#FF3739',
    success: '#00D014',
    info: '#00D0BD',
    white: '#fff',
    lightGrey: '#E8ECEE'
  },
  lineChartAxesColor: '#E9ECEF',
  legendFontColor: '#AAAEB3', // only works on react chart js 2
  chartGridColor: '#EAEAEA',
  axesColor: '#657786',
  shadowColor: 'rgba(0,0,0,0.3)'
};
