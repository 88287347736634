<template>
  <!--HEADER PART-->
  <!-- CONTENT -->
  <v-container
    fluid=""
    style="height: 100%"
    class="pa-0 relative"
  >
    <v-row no-gutters>
      <v-col
        class="pa-4"
        cols="10"
      >
        <div>
          <slot name="beforeHeaderText" />
          <h1
            v-t="header"
            class="text-h6"
          />
          <slot name="afterHeaderText" />
        </div>
        <slot name="subHeader">
          <div
            v-if="subHeader"
          >
            <h4
              v-t="subHeader"
              class="text-subtitle-2"
            />
          </div>
        </slot>
      </v-col>
      <v-col
        v-if="$slots.headerActions"
        class="pa-2 align-self-center text-right"
      >
        <slot name="headerActions" />
      </v-col>
    </v-row>
    <v-divider />
    <v-row
      :style="rowStyle"
      no-gutters
    >
      <slot />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ModuleLayout',
  props: {
    rowStyle: { type: null },
    header: { type: String },
    subHeader: { type: String }
  }
};
</script>

<style scoped></style>
