<template>
  <v-menu
    bottom
    offset-y
    left
    content-class="userblock-dropdown"
    nudge-top="-10"
    nudge-right="0"
    transition="slide-y-transition"
  >
    <template #activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <img
          :src="selectedLocaleImg"
          alt="avatar"
          height="32"
          width="32"
          class="img-responsive"
        >
      </v-btn>
    </template>
    <div class="dropdown-content">
      <v-list
        class="dropdown-list"
        dense
      >
        <template v-for="(locale, ind) in locales">
          <v-list-item
            :key="ind"
            :disabled="locale.disabled"
            @click="currentLocale = locale.value"
          >
            <v-list-item-avatar
              class="my-0 mx-1"
              size="32"
            >
              <v-img :src="locale.icon" />
            </v-list-item-avatar>
            <v-list-item-content>
              <span>{{ $t(locale.name) }}</span>
            </v-list-item-content>
            <v-list-item-action v-if="locale.disabled">
              <span
                class="sidebar-label"
                style="right: 10px; top: 10px"
              >soon</span>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </v-menu>
</template>

<script >
export default {
  name: 'LanguageChanger',
  data () {
    return {
      locales: [
        {
          value: 'en',
          name: 'header.locales.en',
          icon: '/flags/en.png'
        },
        {
          value: 'tr',
          name: 'header.locales.tr',
          icon: '/flags/tr.png',
          disabled: true
        }
      ]
    };
  },
  computed: {
    selectedLocaleImg () {
      return this.locales.find(loc => loc.value === this.currentLocale).icon;
    },
    currentLocale: {
      get () {
        return this.$i18n.locale;
      },
      set (localeValue) {
        this.$i18n.locale = localeValue;
        this.$vuetify.lang.current = localeValue;
      }
    }
  }

};
</script >

<style scoped >

</style >
