const CONFIG = {
  API_URL: process.env.VUE_APP_API_URL,
  API_VERSION: process.env.VUE_APP_API_VERSION,
  OIDC_URL: process.env.VUE_APP_OIDC_URL,
  OIDC_CLIENT_ID: process.env.VUE_APP_OIDC_CLIENT_ID,
  APP_VERSION: process.env.VUE_APP_VERSION,
  SANDBOX_URL: process.env.VUE_APP_SANDBOX_URL,
  IS_DEV: process.env.NODE_ENV === 'development'
};

export default CONFIG;
