<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    slim
  >
    <v-text-field
      v-model="innerValue"
      :outlined="$attrs.outlined !== undefined ? $attrs.outlined : true"
      :error="!!errors.length"
      v-bind="$attrs"
      dense
      v-on="$listeners"
    >
      <template
        #append
      >
        <slot
          name="append"
          :errors="errors"
        />
        <v-tooltip
          v-if="!!errors.length && !$attrs.disabled"
          bottom
        >
          <template
            #activator="{on}"
          >
            <v-icon
              color="error"
              v-on="on"
              @click.prevent.stop=""
            >
              mdi-alert-circle
            </v-icon>
          </template>
          <span>{{ errors[0] }}</span>
        </v-tooltip>
      </template>
      <slot
        v-for="(_, name) in $slots"
        :slot="name"
        :name="name"
      />
    </v-text-field>
  </ValidationProvider>
</template>
<script>
export default {
  name: 'VTextFieldWithValidation',
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-input__prepend-outer, .v-text-field.v-text-field--enclosed .v-input__prepend-inner, .v-text-field.v-text-field--enclosed .v-input__append-inner, .v-text-field.v-text-field--enclosed .v-input__append-outer{
  margin: auto
}
</style>
