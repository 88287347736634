import Vue from 'vue';
import vueOidc from '@/libs/vue-oidc';
import CONFIG from '../utils/CONFIG';

export const oidcSettings = {
  authority: CONFIG.OIDC_URL,
  client_id: CONFIG.OIDC_CLIENT_ID,
  redirect_uri: window.location.origin + '/callback',
  post_logout_redirect_uri: window.location.origin + '/',
  response_type: 'id_token token',
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  scope: 'openid profile email',
  silent_redirect_uri: window.location.origin + '/silent-renew.html',
  extraQueryParams: {
    audience: CONFIG.API_URL
  }
};

Vue.use(vueOidc, {
  config: oidcSettings,
  logLevel: CONFIG.IS_DEV ? 'WARN' : 'NONE'
});
