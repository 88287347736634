<template>
  <div>
    <Loading v-if="!error" />
    <div v-else>
      {{ error }}
    </div>
  </div>
</template>

<script>
import Loading from '@/modules/@core/pages/Loading';
import Vue from 'vue';
export default {
  name: 'OidcCallback',
  components: {
    Loading
  },
  beforeRouteEnter (to, from, next) {
    Vue.$oidc.mgr
      .signinRedirectCallback()
      .then(user => {
        let lastLocation = user.state ? user.state.location : null;
        if (lastLocation) {
          lastLocation = lastLocation.replace(window.location.origin, '');
        }
        window.location.replace(lastLocation || '/');
      })
      .catch((err) => {
        if (err instanceof Error) {
          err = err.message;
        }
        next({ name: 'error', params: { error: err } });
        // this.$router.replace('/')
      });
  }
};
</script>
