
// sidebar filters
export const sidebarFilters = [
  {
    id: 1,
    class: 'sidebar-overlay-dark',
    name: 'message.dark'
  },
  {
    id: 2,
    class: 'sidebar-overlay-light',
    name: 'message.light'
  },
  {
    id: 3,
    class: 'sidebar-overlay-pink',
    name: 'message.danger'
  },
  {
    id: 4,
    class: 'sidebar-overlay-primary',
    name: 'message.primary'
  },
  {
    id: 5,
    class: 'sidebar-overlay-warning',
    name: 'message.warning'
  },
  {
    id: 6,
    class: 'sidebar-overlay-success',
    name: 'message.success'
  },
  {
    id: 7,
    class: 'sidebar-overlay-purple',
    name: 'message.info'
  }
];
