<!-- Header Structure -->
<template>
  <div>
    <v-navigation-drawer
      v-if="!horizontal"
      v-model="drawer"
      app
      fixed
      mini-variant-width="70"
      :width="250"
      class="Vuely-sidebar"
      :class="{'background-none': true}"
    >
      <!-- App Sidebar -->
      <app-sidebar />
    </v-navigation-drawer>
    <v-app-bar
      :class="{'Vuely-toolbar': true, sandbox: !livemode}"
      app
      style="margin-top: 0"
      fixed
    >
      <div class="d-custom-flex align-items-center navbar-left">
        <div v-if="!horizontal">
          <v-app-bar-nav-icon
            class="v-step-0"
            @click.stop="drawer = !drawer"
          />
        </div>
        <div
          v-else
          class="site-logo-wrap d-custom-flex ml-0 align-items-center"
        >
          <router-link
            to="/horizontal/dashboard/ecommerce"
            class="grayish-blue site-logo-img"
          />
        </div>
      </div>
      <div
        class="navbar-right"
        style="display: flex"
      >
        <v-select
          :items="modes"
          :value="livemode"
          class="sandbox-selector"
          dense
          outlined
          hide-details
          prepend-inner-icon="visibility"
          :loading="loading"
          @change="switchMode($event)"
        />
        <language-changer />
        <user-block />
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import Sidebar from './AppSidebar';
import UserBlock from '@/modules/@core/components/UserBlock';
import LanguageChanger from '@/modules/@core/components/LanguageChanger';
import LoadingMixin from '@/modules/@core/mixins/LoadingMixin';

export default {
  components: {
    UserBlock,
    LanguageChanger,
    appSidebar: Sidebar
  },
  props: {
    horizontal: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      collapsed: false, // collapse sidebar
      drawer: null, // sidebar drawer default true
      chatSidebar: false, // chat component right sidebar
      sidebarImages: '', // sidebar background images
      enableDefaultSidebar: false,
      livemode: true,
      modes: [{ text: 'live', value: true }, { text: 'sandbox', value: false }],
      loading: false
    };
  },
  async mounted () {
    this.livemode = this.$store.getters['preferences/getLivemode']();
  },
  methods: {
    async switchMode (livemode) {
      this.loading = true;
      const service = this.$store.state.preferences.selectedTenant;
      await this.$store.dispatch('preferences/setLivemode', { livemode, service_id: service.id });

      this.livemode = livemode;
      this.$router.go(0);
    }
  }
};
</script>

<style scoped>
header.sandbox {
  background: repeating-linear-gradient(
      45deg,
      yellow,
      #ffdf00 100px
  );
}
.sandbox-selector {
  display: inline-block; padding-right: 10px; padding-top: 5px; max-width: 200px
}
</style>
