import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.css';

import VTextFieldWithValidation from '@/modules/@core/components/inputs/VTextFieldWithValidation';
import VTextAreaWithValidation from '@/modules/@core/components/inputs/VTextAreaWithValidation';
import VSelectWithValidation from '@/modules/@core/components/inputs/VSelectWithValidation';
import VComboboxWithValidation from '@/modules/@core/components/inputs/VComboboxWithValidation';
import VCheckboxWithValidation from '@/modules/@core/components/inputs/VCheckboxWithValidation';

Vue.use(Vuetify);

Vue.component('VTextFieldWithValidation', VTextFieldWithValidation);
Vue.component('VTextAreaWithValidation', VTextAreaWithValidation);
Vue.component('VSelectWithValidation', VSelectWithValidation);
Vue.component('VComboboxWithValidation', VComboboxWithValidation);
Vue.component('VCheckboxWithValidation', VCheckboxWithValidation);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#5D92F4',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF3739',
        info: '#00D0BD',
        success: '#00D014',
        warning: '#FFB70F'
      },
      dark: {
        primary: '#5D92F4',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF3739',
        info: '#00D0BD',
        success: '#00D014',
        warning: '#FFB70F'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
});
