import { lazyLoadView } from '../../../utils/lazy_route_loader';

export default [
  {
    path: 'plans',
    component: () => lazyLoadView(import(/* webpackChunkName: "plans" */'@/modules/plans/pages/Plans')),
    meta: { title: 'plans.@page.title' },
    name: 'plans'
  },
  {
    path: 'plans/:id/',
    pathToRegexpOptions: { strict: true },
    component: () => lazyLoadView(import(/* webpackChunkName: "planDetails" */'@/modules/plans/pages/PlanDetails')),
    children: [
      {
        path: 'details',
        name: 'planDetails'
      }
    ]
  }
];
