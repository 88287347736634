<!-- App Main Structure -->
<template>
  <div class="app-default-layout">
    <template v-if="loading">
      <CustomSpinner />
    </template>
    <template v-else>
      <!-- App Header -->
      <app-header />
      <!-- App Main Content -->
      <v-main>
        <!-- App Router -->
        <transition
          name="router-anim"
          :enter-active-class="`animated `"
        >
          <router-view />
        </transition>
      </v-main>
    </template>
    <confirm-dialog ref="confirm" />
  </div>
</template>

<script>
import Header from '@/modules/@core/components/AppHeader';
import CustomSpinner from '@/modules/@core/components/CustomSpinner';
import ConfirmDialog from '@/modules/@core/components/ConfirmDialog';

export default {
  components: {
    appHeader: Header,
    CustomSpinner,
    ConfirmDialog
  },
  data () {
    return {
      loading: true
    };
  },
  computed: {
    tenants () {
      return this.$store.state.tenants.tenants;
    },
    selectedTenant () {
      return this.$store.state.preferences.selectedTenant;
    }
  },
  mounted () {
    this.$root.confirm = this.$refs.confirm;
    this.loading = false;
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }
};
</script>

<style scoped>
  .app-default-layout {
    height: 100vh;
  }
</style>
